import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import SocialCard from '../components/social-card'

import { FeaturedWork } from './work'

import ArrowIcon from '../assets/arrow.svg'
import GitHubIcon from '../assets/github.svg'
import MastodonIcon from '../assets/mastodon.svg'
import TwitterIcon from '../assets/twitter.svg'
import LinkedInIcon from '../assets/linkedin.svg'
import MailIcon from '../assets/mail.svg'

import config from '../../config/site-config'
import Head from '../components/head'
import Meta from '../components/meta'
import Header from '../components/header'

const Hello = () => (
	<div className="homepage--hello">
		<div className="contents">
			<h1 className="name">
				Hi, I’m Harshil <span className="wave">👋🏽</span>
			</h1>
			
			<p>I’m an independent iOS engineer living in Mumbai, India.</p>
			
			<p>I make <a href="https://sneakpeak.app" title="Peak">Peak</a>, an iPhone, iPad, & Apple Watch app that lets you build your own fitness dashboard, and <a href="/pause" title="Pause">Pause</a>, a Mac app that reminds you to rest.</p>
         
         <ElsewhereOnTheInternet />
		</div>
	</div>
)

const ElsewhereOnTheInternet = () => (
	<div className="homepage--elsewhere">
      <ul className="external-links--list">
         <li className="external-links--list-item">
            <a className="external-link" rel="me" href={config.social.mastodon.url} title={"Follow " + config.social.mastodon.username + " on Mastodon"}>
               <MastodonIcon className="external-icon mastodon" />
            </a>
         </li>
         <li className="external-links--list-item">
            <a className="external-link" href={config.social.twitter.url} title={"Follow " + config.social.twitter.username + " on Twitter"}>
               <TwitterIcon className="external-icon twitter" />
            </a>
         </li>
         <li className="external-links--list-item">
            <a className="external-link" href={config.social.github.url} title={"Follow " + config.social.github.username + " on GitHub"}>
               <GitHubIcon className="external-icon github" />
            </a>
         </li>
         <li className="external-links--list-item">
            <a className="external-link" href={config.social.linkedin.url} title={"Connect with " + config.social.linkedin.username + " on LinkedIn"}>
               <LinkedInIcon className="external-icon linkedin" />
            </a>
         </li>
         <li className="external-links--list-item">
            <a className="external-link" href={config.social.email.url} title={`Send an email to ` + config.social.email.username} >
               <MailIcon className="external-icon mail" />
            </a>
         </li>
      </ul>
	</div>
)
	
const Work = () => (
	<div className="homepage--work">
		<div className="contents">
			<h2 className="section-title">Selected Work</h2>
			<FeaturedWork className="list"/>
			<div className="view-all-container">
				<Link to={config.work} title="View All Work" className="view-all">
					View All <ArrowIcon />
				</Link>
			</div>
		</div>
	</div>
)
	
const RecentPosts = ({data}) => (
	<div className="homepage--recent-posts">
		<div className="contents">
			<h2 className="section-title">Recent Posts</h2>
			<ol className="homepage--post-list list">
				{data.allMarkdownRemark.nodes.map(post => {
					const id = post.id
					const title = post.frontmatter.title
					const description = post.frontmatter.description
					const link = config.blogPrefix + post.frontmatter.slug
					const linkTitle = "Read ”" + title + "”"
					
					return(
						<li key={id} className="post-list--item">
							<Link to={link} title={linkTitle} className="post-link">
								<h1 className="title">{title}</h1>
								{ description && <p className="description">{description}</p> }
								<p className="excerpt">{post.excerpt}</p>
							</Link>
						</li>
					)
				})}
			</ol>
			{
				data.allMarkdownRemark.totalCount > blogPostLimit && 
				<div className="view-all-container">
					<Link to={config.blogPrefix} title="View All Posts" className="view-all">
						View All <ArrowIcon />
					</Link>
				</div>
			}
		</div>
	</div>	
)

const year = new Date().getFullYear()

const Footer = () => (
   <div className="homepage--footer">
      <div className="footer">
         <ElsewhereOnTheInternet />
         <p>Copyright © {year} Harshil Shah</p>
      </div>
   </div>
)

const IndexPage = ({data}) => (
	<div className="main">
		<div className="home">
			<Meta />
			<Helmet>
				<html data-style="main" />
			</Helmet>
			<Head title={config.siteTitle} />
			<SocialCard title={config.siteTitle} />
			<Header className="header-wrapper header-home" />
			<Hello />
			<Work />
			<RecentPosts data={data} />
			<Footer />
		</div>
	</div>
)
				
export default IndexPage

const blogPostLimit = 3

export const blogQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/blog\/" } }
			sort: { fields: [frontmatter___date], order: DESC }
			limit: 6
		) {
			totalCount
			nodes {
				id
				excerpt(pruneLength: 100)
				frontmatter {
					title
					description
					slug
				}
			}
		}
	}
`;
